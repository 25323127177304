import React from 'react';
import PropTypes from 'prop-types';

export default function Title({
  className,
  children,
  ...props
}) {
  return <h1 className={`title ${className}`} {...props}>{children}</h1>;
}

Title.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Title.defaultProps = {
  className: '',
};
