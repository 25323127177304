import apiHandler from './apiHandler';

/** Helper function to handle API calls to search for games.
 * @param {string} query - the query from the user.
 * @param {int} limit - the number of results to request.
 * @param {int} offset - the number of results to skip.
 *
 * @returns {array} - with two elements:
 *   {response} - the response from the api call, with json already consumed.
 *   {responseJSON} - the consumed json.
 */
export default async function searchGames(query, limit, offset) {
  const response = await apiHandler(
    `/api/games?query=${query}&limit=${limit}&offset=${offset}`,
    {
      method: 'GET',
    },
  );
  const responseJSON = await response.json();
  return [response, responseJSON];
}
