import React from 'react';
import {
  Redirect,
  useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import { Spinner } from 'components/show';

import { useAuthContext } from './context';

export default function AuthRequired({ children }) {
  const location = useLocation();
  const { authState } = useAuthContext();

  return (
    <>
      {!authState.sessionChecked && <Spinner />}
      {authState.sessionChecked && authState.user && children}
      {authState.sessionChecked && !authState.user
        && (
          <Redirect
            to={`/user/login/?target=${
              encodeURIComponent(location.pathname)
            }`}
          />
        )}
    </>
  );
}

AuthRequired.propTypes = {
  children: PropTypes.node.isRequired,
};
