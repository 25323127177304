import apiHandler from './apiHandler';

/** Helper function to handle API calls to create new users.
 * @param {string} name - the user's name.
 * @param {string} email - the user's email address.
 * @param {string} password - the user's password.
 *
 * @returns {array} - with two elements:
 *   {response} - the response of the api call, with json already consumed.
 *   {responseJSON} - the consumed json.
 */
export default async function register(name, email, password) {
  const response = await apiHandler('/api/users', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      name,
      email,
      password,
    }),
  });
  const responseJSON = await response.json();
  return [response, responseJSON];
}
