import apiHandler from './apiHandler';

/** Helper function to handle API calls to check for an existing session.
 * @param {function} dispatch - will be called with SET_USER if there's an active session.
 * @returns {array} - with two elements:
 *   {response} - the response from the api call, with json already consumed.
 *   {responseJSON} - the consumed json.
 */
export default async function checkSession(dispatch) {
  let response;
  let responseJSON;
  try {
    response = await apiHandler('/api/sessions', { method: 'GET' });
    responseJSON = await response.json();
    if (response.ok) {
      dispatch({
        type: 'SET_USER',
        payload: responseJSON.data,
      });
    }
  } finally {
    dispatch({
      type: 'SET_SESSION_CHECKED',
      payload: true,
    });
  }
  return [response, responseJSON];
}
