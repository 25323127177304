import PropTypes from 'prop-types';

const gameShape = PropTypes.shape({
  cover_height: PropTypes.number,
  cover_url: PropTypes.string,
  cover_url_big: PropTypes.string,
  cover_width: PropTypes.number,
  created_at: PropTypes.string.isRequired,
  first_release_date: PropTypes.string,
  id: PropTypes.number.isRequired,
  igdb_id: PropTypes.number,
  igdb_url: PropTypes.string,
  name: PropTypes.string.isRequired,
  summary: PropTypes.string,
  twitch_id: PropTypes.number,
  updated_at: PropTypes.string,
});

export default gameShape;
