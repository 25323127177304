import React from 'react';

import { placeholderImage } from 'utils';
import { gameShape } from 'utils/propTypes';

export default function BigCover({ game }) {
  return (
    <img
      aria-label={game.cover_url_big ? `${game.name} Cover` : placeholderImage.alt}
      height={game.cover_height || placeholderImage.height}
      src={game.cover_url_big || placeholderImage.src}
      width={game.cover_width || placeholderImage.width}
    />
  );
}

BigCover.propTypes = {
  game: gameShape.isRequired,
};
