// TODO: Allow user to set a fave as a top fave.
import React from 'react';
import PropTypes from 'prop-types';

import { useFaveWizardContext } from 'context/faveWizard';
import { SubTitle } from 'components/show';
import { SubmitButton } from 'components/show/input';
import { FaveForm } from 'components/show/fave';
import { CardBody, CardFoot } from 'components/show/Modal';

export default function EnterMetadata({ formId, handleSubmit }) {
  const { faveWizardState, faveWizardDispatch } = useFaveWizardContext();

  return (
    <>
      <CardBody>
        <div className="container mb-3">
          <SubTitle>Tell us about it!</SubTitle>
          <FaveForm
            formId={formId}
            game={faveWizardState.fave.game}
            handleSubmit={handleSubmit}
            hoursPlayed={faveWizardState.fave.hoursPlayed}
            phrase={faveWizardState.fave.phrase}
            setHoursPlayed={(hoursPlayed) => {
              faveWizardDispatch({
                type: 'SET_NESTED',
                payload: {
                  fave: {
                    hoursPlayed,
                  },
                },
              });
            }}
            setPhrase={(phrase) => faveWizardDispatch({
              type: 'SET_NESTED',
              payload: {
                fave: {
                  phrase,
                },
              },
            })}
          />
        </div>
      </CardBody>
      <CardFoot>
        <button
          type="button"
          aria-label="Go Back"
          className="button"
          onClick={() => faveWizardDispatch({
            type: 'SET',
            payload: {
              step: 1,
            },
          })}
        >
          Go Back
        </button>
        <SubmitButton
          disabled={!faveWizardState.complete}
          formId={formId}
        />
      </CardFoot>
    </>
  );
}

EnterMetadata.propTypes = {
  formId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
