import styled from 'styled-components';

export const Box = styled.div`
  background-color: #111;
  position: relative;
  overflow-wrap: break-word;
`;

export const Button = styled.button`
  position: absolute;
  right: 15px;
  top: 15px;
  width: 30px;
  height: 30px;
  font-size: 0.8rem;
  border-radius: 31px;
`;

export const phraseStyles = [
  {
    className: 'has-text-primary',
    name: 'Super Nintendo',
    style: {
      fontFamily: 'SnesItalic',
      fontSize: '10vh',
      lineHeight: '1',
      marginTop: '1vh',
    },
  },
  {
    className: 'is-size-1',
    name: 'Halo',
    style: {
      color: '#597859',
      fontFamily: 'Halo3',
      lineHeight: '0.8',
    },
  },
  {
    className: 'is-size-4-mobile is-size-3',
    name: 'Namco Arcade',
    style: {
      color: '#FFFF00',
      fontFamily: 'NamcoRegular',
    },
    transformer: (text) => text.toLowerCase(),
  },
  {
    className: 'is-size-1',
    name: '8-Bit Ghouls and Ghosts',
    style: {
      color: '#eee',
      fontFamily: 'EightBitLimit',
      lineHeight: '1.2',
    },
  },
  {
    className: 'is-size-1 ml-1',
    name: 'Super Mario 286',
    style: {
      color: '#ED1C23',
      fontFamily: 'SuperMario286',
      lineHeight: '1.2',
    },
  },
];
