import getFaves from './getFaves';
import apiHandler from './apiHandler';

/** Helper function to handle API calls to delete faves. */
export default async function deleteFave(authFetch, favesDispatch, faveId) {
  const response = await apiHandler(
    `/api/faves/${faveId}`,
    {
      method: 'DELETE',
    },
    authFetch,
  );
  const responseJSON = await response.json();
  if (response.ok) {
    getFaves(favesDispatch, authFetch);
  }
  return [response, responseJSON];
}
