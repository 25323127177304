import React from 'react';
import PropTypes from 'prop-types';

import {
  Title,
} from 'components/show';

import { faveShape } from 'utils/propTypes';

import BigCover from './BigCover';
import FaveDetailsList from './FaveDetailsList';
import GameInfo from './GameInfo';
import PrettyPhrase from './PrettyPhrase';

export default function ReadFave({
  fave,
  setModalActive,
}) {
  return (
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="columns">
              <div className="column is-one-quarter">
                <BigCover game={fave.game} />
              </div>
              <div className="column">
                <Title>{fave.game.name}</Title>
                <div className="columns is-mobile is-vcentered">
                  <div className="column">
                    <FaveDetailsList fave={fave} />
                  </div>
                  <div className="column is-2">
                    <button
                      aria-label="Edit Fave"
                      className="button is-primary is-inverted m-0"
                      onClick={() => { setModalActive(true); }}
                      type="button"
                    >
                      <span className="icon">
                        <i className="fas fa-edit fa-lg" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="column mt-0">
              <div className="content mb-2">
                <p className="is-size-4">You love it because:</p>
              </div>
              <PrettyPhrase text={fave.phrase} />
            </div>
          </div>
          <div className="column is-one-quarter">
            <GameInfo game={fave.game} />
          </div>
        </div>
      </div>
    </section>
  );
}

ReadFave.propTypes = {
  fave: faveShape.isRequired,
  setModalActive: PropTypes.func.isRequired,
};
