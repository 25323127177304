import { login as apiCall } from 'utils/api';
import { reduceErrors } from 'utils';

export default function login(email, password, target = '/') {
  return async function loginAction(authDispatch, { history }) {
    const [response, responseJSON] = await apiCall(authDispatch, email, password);
    if (response.ok) {
      history.replace(`${target || '/'}`);
    } else {
      window.alert(reduceErrors(responseJSON.errors));
    }
  };
}
