import React from 'react';
import PropTypes from 'prop-types';

import Field from './helpers/Field';

/**
* Accepts the same props as `Field`, but defaults appropriate values.
*/
export default function Email({
  value, // React state getter
  setValue, // React state setter
  name,
  label,
  placeholder,
  borderColor,
  iconLeft,
  iconRight,
  ...props // Additional props to pass into the `input` tag, like `required`.
}) {
  return (
    <Field
      name={name}
      label={label}
      iconLeft={iconLeft}
      iconRight={iconRight}
    >
      <input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        name={name}
        id={`${name}-input`}
        type="email"
        className={`input${borderColor ? ` is-${borderColor}` : ''}`}
        placeholder={placeholder}
        {...props}
      />
    </Field>
  );
}

Email.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  borderColor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(null),
  ]),
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
};

Email.defaultProps = {
  name: 'email',
  label: 'Email',
  borderColor: 'primary',
  iconLeft: 'envelope',
  iconRight: null,
  placeholder: 'Enter your email',
};
