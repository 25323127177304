import React from 'react';
import PropTypes from 'prop-types';

export default function Notification({ children, color, modifier }) {
  return (
    <div
      className={
        `notification is-${color}${modifier
          ? ` is-${modifier}`
          : ''}`
      }
    >
      {children}
    </div>
  );
}

Notification.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  modifier: PropTypes.string,
};

Notification.defaultProps = {
  color: 'primary',
  modifier: null,
};
