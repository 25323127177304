import React from 'react';
import PropTypes from 'prop-types';

export default function CardFoot({
  children,
}) {
  return (
    <footer className="modal-card-foot">
      {children}
    </footer>
  );
}

CardFoot.propTypes = {
  children: PropTypes.node.isRequired,
};
