import { patchFave as apiCall } from 'utils/api';
import { reduceErrors } from 'utils';

// eslint-disable-next-line import/prefer-default-export
export function patchFave(authFetch, faveId, payload, index, callback) {
  return async function patchFaveAction(favesDispatch) {
    const [response, responseJSON] = await apiCall(
      authFetch,
      favesDispatch,
      faveId,
      index,
      payload,
    );
    if (response.ok) {
      callback();
    } else {
      window.alert(reduceErrors(responseJSON.errors));
    }
  };
}
