import apiHandler from './apiHandler';

/** Helper function to handle API calls to delete user sessions.
 * @param {function} dispatch - SET_USER called with `null` if response is successful.
 *
 * @returns {array} - with two elements:
 *   {response} - the response from the api call, with json already consumed.
 *   {responseJSON} - the consumed json.
 */
export default async function logout(dispatch) {
  const response = await apiHandler('/api/sessions', { method: 'DELETE' });
  const responseJSON = await response.json();
  dispatch({
    type: 'CLEAR_USER',
  });
  return [response, responseJSON];
}
