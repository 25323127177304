import React from 'react';
import PropTypes from 'prop-types';

import Field from './helpers/Field';

/**
* Accepts the same props as `Field`, but defaults appropriate values.
*/
export default function TextBox({
  value, // React state getter
  setValue, // React state setter
  name,
  label,
  borderColor,
  iconLeft,
  iconRight,
  ...props // Additional props to pass into the `input` tag, like `required`.
}) {
  return (
    <Field
      name={name}
      label={label}
      iconLeft={iconLeft}
      iconRight={iconRight}
    >
      <input
        name={name}
        value={value}
        id={`${name}-input`}
        onChange={(e) => setValue(e.target.value)}
        type="text"
        className={`input${borderColor ? ` is-${borderColor}` : ''}`}
        {...props}
      />
    </Field>
  );
}

TextBox.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  borderColor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(null),
  ]),
};

TextBox.defaultProps = {
  iconLeft: null,
  iconRight: null,
  borderColor: 'primary',
};
