import { searchGames as apiCall } from 'utils/api';
import { reduceErrors } from 'utils';

export default function searchGames(query) {
  return async function searchGamesAction(dispatch) {
    dispatch({
      type: 'SET',
      payload: {
        isLoading: true,
      },
    });
    const [response, responseJSON] = await apiCall(query, 10, 0);
    if (response.ok) {
      dispatch({
        type: 'SET_NESTED',
        payload: {
          lastSearch: {
            query,
            data: responseJSON.data,
          },
          fave: {
            game: null,
          },
        },
      });
    } else {
      window.alert(reduceErrors(responseJSON.errors));
    }
    dispatch({
      type: 'SET',
      payload: {
        isLoading: false,
      },
    });
  };
}
