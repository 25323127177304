import apiHandler from './apiHandler';

/** Helper function to handle API calls to get user's faves. */
export default async function getFaves(favesDispatch, authFetch) {
  const response = await apiHandler(
    '/api/faves',
    {
      method: 'GET',
    },
    authFetch,
  );
  const responseJSON = await response.json();
  if (response.ok) {
    favesDispatch({
      type: 'SET',
      payload: {
        faves: responseJSON.data,
      },
    });
  }
  favesDispatch({
    type: 'SET',
    payload: {
      sessionChecked: true,
    },
  });
  return [response, responseJSON];
}
