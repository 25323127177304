import React from 'react';
import PropTypes from 'prop-types';
/*
* Reusable Bulma Action Button.
* Usage:
* <ActionButton buttonId="add-fave-button" onClick={openModal} icon="heart" />
*/
function ActionButton({
  altText, buttonId, onClick, icon,
}) {
  return (
    <button type="button" aria-label={altText} onClick={onClick} id={buttonId} className="button is-floating is-primary">
      <i className={`fas fa-${icon}`} />
    </button>
  );
}

ActionButton.propTypes = {
  altText: PropTypes.string.isRequired,
  buttonId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
};

export default ActionButton;
