import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { useCustomReducer } from 'utils';

import initialState from './initialState';
import reducer from './reducer';

const AuthContext = React.createContext();

function AuthProvider({ children, testInitialState }) {
  const [authState, authDispatch] = useCustomReducer(
    reducer,
    testInitialState === null ? initialState : testInitialState,
  );
  return (
    <AuthContext.Provider value={{ authState, authDispatch }}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  testInitialState: PropTypes.shape(),
};

AuthProvider.defaultProps = {
  testInitialState: null,
};

// Since AuthContext is not exported, the only way to use it is inside AuthProvider.
function useAuthContext() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuthContext };
