import { Figure } from 'components/show';

import placeholder from 'utils/placeholder.png';

const gameTableColumns = [
  {
    name: 'Cover',
    node: Figure,
    props: {
      alt: (el) => {
        if (el.cover_url) {
          return `${el.name} Cover Image`;
        }
        return 'Placeholder Image';
      },
      size: '64x64',
      src: (el) => el.cover_url || placeholder,
    },
  },
  {
    name: 'Name',
    makeDirectChild: (el) => el.name,
  },
  {
    name: 'Release Date',
    node: 'span',
    props: {
      children: (el) => el.first_release_date || 'Not Listed',
    },
  },
];

export default gameTableColumns;
