import React from 'react';
import PropTypes from 'prop-types';

import Field from './helpers/Field';

/**
* Accepts the same props as `Field`, but defaults appropriate values.
* arbitrary props will be passed
*/
export default function Password({
  value, // React state getter
  setValue, // React state setter
  name,
  label,
  placeholder,
  borderColor,
  iconLeft,
  iconRight,
  ...props // Additional props to pass into the `input` tag, like `required`.
}) {
  return (
    <Field
      name={name}
      label={label}
      iconLeft={iconLeft}
      iconRight={iconRight}
    >
      <input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        name={name}
        id={`${name}-input`}
        type="password"
        className={`input${borderColor ? ` is-${borderColor}` : ''}`}
        placeholder={placeholder}
        {...props}
      />
    </Field>
  );
}

Password.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  placeholder: PropTypes.string,
  borderColor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(null),
  ]),
};

Password.defaultProps = {
  name: 'password',
  label: 'Password',
  borderColor: 'primary',
  iconLeft: 'lock',
  iconRight: null,
  placeholder: 'Enter password',
};
