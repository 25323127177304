/**
* Create a reducer with the following baked-in action types:
*   SET: sets a top-level value. If targeting an object, pass the entire object.
*   SET_NESTED: sets a nested value.
*   RESET: reset a top-level value to initialState value.
*   RESET_ALL: reset entire state to initialState.
*
* @param {obj} initialState.
*/
export default function makeReducer(initialState, myReducer) {
  function validatePayload(payload) {
    const payloadKeys = Array.isArray(payload) ? payload : Object.keys(payload);
    payloadKeys.forEach((key) => {
      if (Object.keys(initialState).indexOf(key) === -1) {
        throw new Error(
          `Cannot add new property ${key}. Update initialState to add new properties.`,
        );
      }
    });
  }

  function consumeNestedPayload(currentState, payload) {
    return Object.keys(payload).reduce((acc, key) => {
      acc[key] = {
        ...currentState[key],
        ...payload[key],
      };
      return acc;
    }, {});
  }

  function consumeResetPayload(payload) {
    return payload.reduce((acc, key) => {
      acc[key] = initialState[key];
      return acc;
    }, {});
  }

  return function reducer(currentState, action) {
    if (myReducer !== undefined) {
      const answer = myReducer(currentState, action);
      if (answer !== undefined) {
        return answer;
      }
    }
    if (action.payload) {
      validatePayload(action.payload);
    }
    switch (action.type) {
      case 'SET': {
        return {
          ...currentState,
          ...action.payload,
        };
      }
      case 'SET_NESTED': {
        return {
          ...currentState,
          ...consumeNestedPayload(currentState, action.payload),
        };
      }
      case 'RESET': {
        return {
          ...currentState,
          ...consumeResetPayload(action.payload),
        };
      }
      case 'RESET_ALL': {
        return initialState;
      }
      default:
        throw new Error(`Unhandled dispatch action type: ${action.type} ${action}`);
    }
  };
}
