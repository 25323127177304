import React from 'react';
import PropTypes from 'prop-types';

import { useAuthFetch } from 'context/auth';
import { useFavesContext } from 'context/faves';

import { deleteFave } from 'utils/api';

export default function DeleteFaveButton({
  children,
  faveId,
  node,
  ...props
}) {
  const authFetch = useAuthFetch();
  const { favesDispatch } = useFavesContext();

  function onClick(event) {
    event.stopPropagation();
    if (window.confirm('Delete Fave? Are you sure?')) {
      deleteFave(authFetch, favesDispatch, faveId);
    }
  }

  return React.createElement(node, { onClick, ...props }, children);
}

DeleteFaveButton.propTypes = {
  children: PropTypes.node,
  faveId: PropTypes.number.isRequired,
  node: PropTypes.elementType,
};

DeleteFaveButton.defaultProps = {
  children: 'Delete',
  node: 'button',
};
