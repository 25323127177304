import React from 'react';
import PropTypes from 'prop-types';

export default function IconButton({
  ariaLabel,
  icon,
  iconSize,
  ...props
}) {
  return (
    <button
      aria-label={ariaLabel}
      type="button"
      {...props}
    >
      <span className="icon">
        <i className={`${icon} ${iconSize}`} />
      </span>
    </button>
  );
}

IconButton.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconSize: PropTypes.string,
};

IconButton.defaultProps = {
  iconSize: '',
};
