import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  phraseStyles,
} from './styles';

export default function PrettyPhrase({ text }) {
  const buttonRef = useRef(null);
  const [index, setIndex] = useState(0);

  function nextStyle() {
    buttonRef.current.blur();
    setIndex(index < phraseStyles.length - 1 ? index + 1 : 0);
  }

  return (
    <Box
      aria-label={`The phrase "${text}" in ${phraseStyles[index].name} style.`}
      className="box"
      role="figure"
    >
      <Button
        aria-label="View Next Style"
        ref={buttonRef}
        className="button is-primary is-outlined"
        onClick={nextStyle}
      >
        <span className="icon is-small">
          <i className="fas fa-redo" />
        </span>
      </Button>
      <div
        style={phraseStyles[index].style}
        className={phraseStyles[index].className}
      >
        {phraseStyles[index].transformer ? phraseStyles[index].transformer(text) : text}
      </div>
    </Box>
  );
}

PrettyPhrase.propTypes = {
  text: PropTypes.string.isRequired,
};
