import React from 'react';
import PropTypes from 'prop-types';

export default function SubmitButton({
  className,
  formId,
  value,
  color,
  ...props
}) {
  return (
    <div className="field">
      <div className="control">
        <input
          form={formId}
          className={`button${color ? ` is-${color}` : ''} ${className}`}
          type="submit"
          value={value}
          {...props}
        />
      </div>
    </div>
  );
}

SubmitButton.propTypes = {
  formId: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
};

SubmitButton.defaultProps = {
  formId: null,
  value: 'Submit',
  className: '',
  color: 'success',
};
