import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AuthDependentProvider } from 'context/auth';

import initialState from './initialState';
import reducer from './reducer';

const Context = React.createContext();

function FavesProvider({ children, testInitialState }) {
  return (
    <AuthDependentProvider
      Context={Context}
      contextName="faves"
      initialState={testInitialState === null ? initialState : testInitialState}
      reducer={reducer}
    >
      {children}
    </AuthDependentProvider>
  );
}
FavesProvider.propTypes = {
  children: PropTypes.node.isRequired,
  testInitialState: PropTypes.shape(),
};

FavesProvider.defaultProps = {
  testInitialState: null,
};

function useFavesContext() {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('useFavesContext must be used within a FavesProvider');
  }
  return context;
}

export { useFavesContext, FavesProvider };
