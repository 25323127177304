import getFaves from './getFaves';
import apiHandler from './apiHandler';

/** Helper function to handle API calls to update faves. */
export default async function patchFave(
  authFetch,
  favesDispatch,
  faveId,
  faveIndex,
  {
    deleted, hoursPlayed, topFave, phrase,
  },
) {
  const response = await apiHandler(
    `/api/faves/${faveId}`,
    {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        deleted,
        hours_played: hoursPlayed,
        phrase,
        top_fave: topFave,
      }),
    },
    authFetch,
  );
  const responseJSON = await response.json();
  if (response.ok) {
    getFaves(favesDispatch, authFetch);
  }
  return [response, responseJSON];
}
