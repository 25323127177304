import React from 'react';
import PropTypes from 'prop-types';

function keyDownA11y(handler) {
  return function onKeyDown(event) {
    if (
      ['keydown', 'keypress'].includes(event.type)
      && ['Enter', ' '].includes(event.key)
    ) {
      handler();
    }
  };
}

export default function A11yButton({
  elementType,
  onClick,
  ...props
} = {}) {
  return React.createElement(elementType, {
    ...props,
    onClick,
    onKeyDown: keyDownA11y(onClick),
    role: 'button',
    tabIndex: 0,
  });
}

A11yButton.propTypes = {
  elementType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
