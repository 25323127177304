import { useLocation, useHistory } from 'react-router-dom';

import { useAuthContext } from './context';

export default function useAuthFetch() {
  const { authDispatch } = useAuthContext();
  const history = useHistory();
  const location = useLocation();
  async function authFetch(resource, init) {
    const response = await fetch(resource, init);
    if (response.status === 401) {
      authDispatch({
        type: 'CLEAR_USER',
      });
      history.push(`/user/login?target=${
        encodeURIComponent(location.pathname)
      }`);
    }
    return response;
  }
  return authFetch;
}
