import React from 'react';
import PropTypes from 'prop-types';

import placeholder from 'utils/placeholder.png';

export default function Figure({
  alt, size, src, figureProps, imgProps,
}) {
  return (
    <figure className={`image is-${size}`} {...figureProps}>
      <img src={src} alt={alt} {...imgProps} />
    </figure>
  );
}

Figure.propTypes = {
  alt: PropTypes.string,
  figureProps: PropTypes.shape(),
  imgProps: PropTypes.shape(),
  size: PropTypes.string,
  src: PropTypes.string,
};

Figure.defaultProps = {
  alt: 'Placeholder Image',
  figureProps: {},
  imgProps: {},
  size: '64x64',
  src: placeholder,
};
