import React from 'react';
import PropTypes from 'prop-types';

export default function Label({
  name, label, children,
}) {
  return (
    <label className="label" htmlFor={`${name}-input`}>
      {label}
      {children}
    </label>
  );
}

Label.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
};

Label.defaultProps = {
  children: null,
};
