import React, { useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import 'App.scss';
import {
  AuthRequired,
  useAuthContext,
  useAuthFetch,
} from 'context/auth';
import { useFavesContext } from 'context/faves';
import { FaveWizardProvider } from 'context/faveWizard';

import {
  FridgeNav,
  HomePage,
} from 'components/know';
import {
  LoginForm,
  RegistrationForm,
} from 'components/know/user';
import {
  FaveWizard,
  ViewFave,
  ViewFavesList,
} from 'components/know/fave';
import { Spinner } from 'components/show';
import { NoFaves } from 'components/show/fave';

import {
  checkSession,
  getFaves,
} from 'utils/api';

function App() {
  const { authDispatch, authState } = useAuthContext();
  const authFetch = useAuthFetch();
  const { favesState, favesDispatch } = useFavesContext();

  useEffect(() => {
    checkSession(authDispatch);
  }, []);
  useEffect(() => {
    if (authState.user) {
      getFaves(favesDispatch, authFetch);
    }
  }, [authState.user]);

  return (
    <>
      <FridgeNav />
      <Switch>
        <Route path="/" exact>
          {authState.sessionChecked ? <HomePage /> : <Spinner />}
        </Route>
        <Route path="/fave/:index">
          <AuthRequired>
            <ViewFave />
          </AuthRequired>
        </Route>
        <Route path="/my-faves">
          <AuthRequired>
            {favesState.faves.length > 0 || !favesState.sessionChecked
              ? <ViewFavesList faves={favesState.faves} />
              : <NoFaves />}
          </AuthRequired>
        </Route>
        <Route path="/user/register">
          {authState.user
            ? <Redirect to="/" />
            : <RegistrationForm />}
        </Route>
        <Route path="/user/login">
          {authState.user
            ? <Redirect to="/" />
            : <LoginForm />}
        </Route>
      </Switch>
      <FaveWizardProvider>
        {authState.user && <FaveWizard />}
      </FaveWizardProvider>
    </>
  );
}

export default App;
