import PropTypes from 'prop-types';

import gameShape from './gameShape';

const faveShape = PropTypes.exact({
  game: gameShape.isRequired,
  id: PropTypes.number.isRequired,
  user_id: PropTypes.number.isRequired,
  game_id: PropTypes.number.isRequired,
  top_fave: PropTypes.bool.isRequired,
  phrase: PropTypes.string.isRequired,
  hours_played: PropTypes.number.isRequired,
  deleted: PropTypes.bool.isRequired,
  created_at: PropTypes.string.isRequired,
  updated_at: PropTypes.string,
  deleted_at: PropTypes.string,
});

export default faveShape;
