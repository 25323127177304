import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import { AuthProvider } from 'context/auth';
import { FavesProvider } from 'context/faves';

import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <FavesProvider>
          <App />
        </FavesProvider>
      </AuthProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
