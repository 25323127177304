import React from 'react';
import PropTypes from 'prop-types';

import Control from './Control';
import Label from './Label';

/**
* You usually wouldn't want to use `Field` directly.
*/
export default function Field({
  name, label,
  iconLeft, iconRight,
  children,
}) {
  return (
    <div className="field">
      <Label name={name} label={label}>
        <Control iconLeft={iconLeft} iconRight={iconRight}>
          {React.Children.only(children)}
        </Control>
      </Label>
    </div>
  );
}

Field.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Field.defaultProps = {
  iconLeft: null,
  iconRight: null,
};
