import React from 'react';
import PropTypes from 'prop-types';

import { faveShape } from 'utils/propTypes';

import Fave from './Fave';

export default function MoreFavesDropdown({
  children,
  data,
}) {
  return (
    <div className="navbar-item has-dropdown is-hoverable">
      <div type="button" aria-label="More Faves" aria-expanded="false" className="link navbar-item">
        More Faves
      </div>
      <div className="navbar-dropdown">
        {data.map((fave, i) => (
          <Fave
            data={fave}
            key={fave.id}
            route={`/fave/${i + 3}`}
          />
        ))}
        {children}
      </div>
    </div>
  );
}

MoreFavesDropdown.propTypes = {
  children: PropTypes.node,
  data: PropTypes.arrayOf(faveShape).isRequired,
};

MoreFavesDropdown.defaultProps = {
  children: null,
};
