import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import Modal, {
  CardBody,
  CardFoot,
  CardHead,
} from 'components/show/Modal';
import { FaveForm } from 'components/show/fave';
import { SubmitButton } from 'components/show/input';

import { useAuthFetch } from 'context/auth';
import { useFavesContext } from 'context/faves';

import { patchFave } from 'context/faves/actions';
import { faveShape } from 'utils/propTypes';

export default function EditFaveModal({
  fave,
  index,
  modalActive,
  setModalActive,
}) {
  const authFetch = useAuthFetch();
  const { favesDispatch } = useFavesContext();
  const formId = 'edit-fave-form';

  function initialState(myFave) {
    return {
      phrase: myFave.phrase,
      hoursPlayed: myFave.hours_played,
      payload: {},
      complete: false,
    };
  }
  function reducer(currentState, update) {
    return {
      ...currentState,
      ...update,
    };
  }
  const [state, dispatch] = useReducer(reducer, initialState(fave));

  useEffect(() => {
    dispatch(initialState(fave));
  }, [fave]);

  useEffect(() => {
    const complete = (
      Boolean(state.phrase)
      && Boolean(state.hoursPlayed)
      && Object.keys(state.payload).length > 0
    );
    if (complete !== state.complete) {
      dispatch({ complete });
    }
  }, [state]);

  async function handleSubmit(event) {
    event.preventDefault();
    favesDispatch(patchFave(
      authFetch,
      fave.id,
      state.payload,
      index,
      () => { setModalActive(false); },
    ));
  }

  function setHoursPlayed(newHoursPlayed) {
    const { hoursPlayed, ...payload } = state.payload;
    if (newHoursPlayed && newHoursPlayed !== fave.hours_played) {
      payload.hoursPlayed = Number(newHoursPlayed);
    }
    dispatch({
      hoursPlayed: Number(newHoursPlayed),
      payload,
    });
  }

  function setPhrase(newPhrase) {
    const { phrase, ...payload } = state.payload;
    if (newPhrase && newPhrase !== fave.phrase) {
      payload.phrase = newPhrase;
    }
    dispatch({
      phrase: newPhrase,
      payload,
    });
  }

  return (
    <Modal
      active={modalActive}
      isCard
      name="Edit Fave"
      setActive={setModalActive}
    >
      <CardHead
        setActive={setModalActive}
        title={`Edit Fave: ${fave.game.name}`}
      />
      <CardBody>
        <FaveForm
          formId={formId}
          game={fave.game}
          handleSubmit={handleSubmit}
          hoursPlayed={state.hoursPlayed}
          phrase={state.phrase}
          setHoursPlayed={setHoursPlayed}
          setPhrase={setPhrase}
        />
      </CardBody>
      <CardFoot>
        <SubmitButton
          disabled={!state.complete}
          formId={formId}
        />
      </CardFoot>
    </Modal>
  );
}

EditFaveModal.propTypes = {
  fave: faveShape.isRequired,
  index: PropTypes.number.isRequired,
  modalActive: PropTypes.bool.isRequired,
  setModalActive: PropTypes.func.isRequired,
};
