import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useCustomReducer } from 'utils';

import { useAuthContext } from './context';

/**
* Create a provider that will reset if the user changes
*/
export default function AuthDependentProvider({
  cache,
  children,
  Context,
  contextName,
  initialState,
  reducer,
}) {
  const { authState } = useAuthContext();

  function authDependentReducer(currentState, action) {
    if (action.type === 'AUTH_RESET') {
      return {
        ...initialState,
        userId: authState.user && authState.user.id,
      };
    }
    if (action.payload) {
      const payloadKeys = Array.isArray(action.payload)
        ? action.payload
        : Object.keys(action.payload);
      if (payloadKeys.indexOf('userId') >= 0) {
        throw new Error(`Not allowed to target userId from AuthDependentProvider ${contextName}`);
      }
    }
    return reducer(currentState, action);
  }

  function init(iS) {
    return {
      ...iS,
      userId: authState.user && authState.user.id,
    };
  }

  const [state, dispatch] = useCustomReducer(authDependentReducer, initialState, init);

  useEffect(() => {
    if (Object.keys(state).indexOf('userId') === -1) {
      throw new Error('AuthDependentProvider state must contain userId key.');
    }
  }, [state]);

  useEffect(() => {
    if (
      (authState.user && authState.user.id !== state.userId)
      || (!cache && !authState.user && state.userId)
    ) {
      dispatch({
        type: 'AUTH_RESET',
      });
    }
  }, [authState.user, state.userId]);

  return (
    <Context.Provider value={{
      [`${contextName}State`]: state,
      [`${contextName}Dispatch`]: dispatch,
    }}
    >
      {children}
    </Context.Provider>
  );
}

AuthDependentProvider.propTypes = {
  cache: PropTypes.bool,
  children: PropTypes.node,
  Context: PropTypes.shape().isRequired,
  contextName: PropTypes.string.isRequired,
  initialState: PropTypes.shape({
    userId: null,
  }).isRequired,
  reducer: PropTypes.func.isRequired,
};

AuthDependentProvider.defaultProps = {
  cache: false,
  children: null,
};
