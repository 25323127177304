import React from 'react';
import PropTypes from 'prop-types';

export default function SubTitle({ children, className, ...props }) {
  return <h2 className={`subtitle ${className}`} {...props}>{children}</h2>;
}

SubTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

SubTitle.defaultProps = {
  className: '',
};
