import React from 'react';
import PropTypes from 'prop-types';

export default function Form({
  children,
  handleSubmit,
  id,
}) {
  return (
    <form id={id} onSubmit={handleSubmit}>
      {children}
    </form>
  );
}

Form.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  id: PropTypes.string,
};

Form.defaultProps = {
  id: null,
};
