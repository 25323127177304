import apiHandler from './apiHandler';

/** Helper function to handle API calls to create new sessions.
 * @param {function} dispatch - will be called with SET_USER on successful login.
 * @param {string} email - the user's email address.
 * @param {string} password - the user's password.
 *
 * @returns {array} - with two elements:
 *   {response} - the response from the api call, with json already consumed.
 *   {responseJSON} - the consumed json.
 */
export default async function login(dispatch, email, password) {
  const response = await apiHandler('/api/sessions', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email,
      password,
    }),
  });
  const responseJSON = await response.json();
  if (response.ok) {
    dispatch({
      type: 'SET_USER',
      payload: responseJSON.data,
    });
  }
  return [response, responseJSON];
}
