import React from 'react';
import PropTypes from 'prop-types';
import { faveShape } from 'utils/propTypes';
import NavLink from './NavLink';

export default function Fave({
  data,
  route,
}) {
  return (
    <NavLink
      activeClassName="is-active"
      className="navbar-item"
      to={route}
    >
      {data.game.name}
    </NavLink>
  );
}

Fave.propTypes = {
  data: faveShape.isRequired,
  route: PropTypes.string.isRequired,
};
