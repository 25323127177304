import React from 'react';
import PropTypes from 'prop-types';

export default function CardBody({
  children,
}) {
  return (
    <section className="modal-card-body">
      {children}
    </section>
  );
}

CardBody.propTypes = {
  children: PropTypes.node.isRequired,
};
