import querystring from 'querystring';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useAuthContext } from 'context/auth';
import { login } from 'context/auth/actions';

import {
  Form,
  Notification,
  Title,
} from 'components/show';

import { Email, Password, SubmitButton } from 'components/show/input';

function LoginForm() {
  const location = useLocation();
  const { target } = querystring.parse(location.search.slice(1));

  const { authDispatch } = useAuthContext();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function handleSubmit(event) {
    event.preventDefault();
    authDispatch(login(email, password, target));
  }

  return (
    <section className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-half">
            <Title className="has-text-centered">Log In To The Refrigerator</Title>
            <Notification color="link" modifier="light">
              <Form handleSubmit={handleSubmit}>
                <Email
                  value={email}
                  setValue={setEmail}
                  color="primary"
                  required
                />
                <Password
                  value={password}
                  setValue={setPassword}
                  required
                />
                <div className="field is-grouped">
                  <SubmitButton className="mr-2" disabled={!email || !password} />
                  <div className="control">
                    <Link className="button is-primary is-outlined" to="/">Cancel</Link>
                  </div>
                </div>
              </Form>
              <p className="has-text-black">
                Don&apos;t have an accout?
                <Link className="is-size-5 ml-2 mr-1 has-text-link" to="/user/register">Register</Link>
                instead!
              </p>
            </Notification>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginForm;
