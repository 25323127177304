import React from 'react';
import Clamp from 'react-multiline-clamp';

import { gameShape } from 'utils/propTypes';

export default function GameInfo({ game }) {
  return (
    <>
      <table className="table">
        <caption className="is-size-5">About This Game</caption>
        <tbody aria-label="Table Body">
          <tr aria-label="First Released">
            <td>First Released</td>
            <td>{game.first_release_date || <>Not Listed &#x1F47B;</>}</td>
          </tr>
          <tr aria-label="Summary">
            <td>Summary</td>
            <td>
              {game.summary
                ? (
                  <Clamp
                    withtooltip
                    withToggle
                    lines={3}
                    maxLines={15}
                  >
                    {game.summary}
                  </Clamp>
                )
                : <span>Not Listed &#x1F47B;</span>}
            </td>
          </tr>
        </tbody>
      </table>
      {game.igdb_url
        ? <a className="ml-3" href={game.igdb_url} target="blank">More Info on IGDB</a>
        : <span>This is a custom game.</span>}
    </>
  );
}

GameInfo.propTypes = {
  game: gameShape.isRequired,
};
