import twoByThree from './twoByThree.png';

const img = {
  src: twoByThree,
  alt: 'Refrigerator Icon 2x3',
  bulmaFigureSize: '2by3',
  height: 990,
  width: 660,
};

export default img;
