// TODO: Allow user to set a fave as a top fave.
import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'components/show';
import { NumberInput, TextBox } from 'components/show/input';

import { gameShape } from 'utils/propTypes';

function FaveForm({
  formId,
  game,
  handleSubmit,
  hoursPlayed,
  phrase,
  setHoursPlayed,
  setPhrase,
}) {
  return (
    <Form
      id={formId}
      handleSubmit={handleSubmit}
    >
      <TextBox
        value={phrase}
        setValue={setPhrase}
        name="phrase"
        label={`Why do you love ${game.name}?`}
        placeholder="It's a great game! What's not to love?"
        required
      />
      <NumberInput
        value={hoursPlayed}
        setValue={setHoursPlayed}
        name="hours-played"
        label="Hours Played"
        iconLeft="clock"
        required
      />
    </Form>
  );
}

FaveForm.propTypes = {
  formId: PropTypes.string.isRequired,
  game: gameShape.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hoursPlayed: PropTypes.number.isRequired,
  phrase: PropTypes.string.isRequired,
  setHoursPlayed: PropTypes.func.isRequired,
  setPhrase: PropTypes.func.isRequired,
};

export default FaveForm;
