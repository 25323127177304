import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';

export default function WrappedNavLink(props) {
  const ref = useRef(null);
  function blur() {
    ref.current.blur();
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <NavLink onClick={blur} ref={ref} {...props} />;
}
