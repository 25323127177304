import PropTypes from 'prop-types';

import gameShape from './gameShape';

const faveWizardCacheShape = {
  userId: PropTypes.number,
  faveGame: PropTypes.shape(gameShape),
  faveTopFave: PropTypes.bool,
  favePhrase: PropTypes.string,
  faveHoursPlayed: PropTypes.number,
  lastSearchQuery: PropTypes.string,
  lastSearchData: PropTypes.arrayOf(PropTypes.shape(gameShape)),
  step: PropTypes.number,
};

export default faveWizardCacheShape;
