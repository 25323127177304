import apiHandler from './apiHandler';

/** Helper function to handle API calls to create new faves. */
export default async function addFave(
  authFetch,
  gameId,
  topFave,
  phrase,
  hoursPlayed,
) {
  const response = await apiHandler(
    '/api/faves',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        game_id: gameId,
        top_fave: topFave,
        phrase,
        hours_played: hoursPlayed,
      }),
    },
    authFetch,
  );
  const responseJSON = await response.json();
  return [response, responseJSON];
}
