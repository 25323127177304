import React from 'react';
import PropTypes from 'prop-types';

/**
* Pass `selectedState` to enable highlighting the currently selected row.
* `idField` must be the same in `data` elements and the `selectedState` object.
*/
function ClickableTable({
  caption,
  captionSize,
  columns,
  data,
  idField,
  nameField,
  onClick,
  rowRole,
  selectedState,
  tableClass,
}) {
  function addTextIfIdSelected(id, str) {
    if (selectedState && id === selectedState[idField]) {
      return str;
    }
    return '';
  }

  return (
    <table className={`table is-hoverable ${tableClass}`}>
      <caption className={`is-size-${String(captionSize)} mb-5 mt-5`}>{caption}</caption>
      <thead aria-label="Table Header">
        <tr aria-label="Column Names">
          {columns.map((col) => (
            <th key={`column-header-${col.name}`} scope="col" className={col.hiddenMobile ? 'is-hidden-mobile' : ''}>
              {col.displayName !== false && col.name}
            </th>
          ))}
        </tr>
      </thead>
      {data && (
      <tbody aria-label="Table Body">
        {data.map((el, i) => (
          <tr
            id={el[idField]}
            key={el[idField]}
            onClick={() => onClick(el, i)}
            role={rowRole}
            aria-label={`${i + 1}: ${el[nameField]}${addTextIfIdSelected(el[idField], ' (Currently Selected)')}`}
            className={`is-clickable${addTextIfIdSelected(el[idField], ' is-selected')}`}
          >
            {columns.map((col) => (
              <td key={`${el[idField]} ${col.name}`} className={col.hiddenMobile ? 'is-hidden-mobile' : ''}>
                <Cell column={col} element={el} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      )}
    </table>
  );
}

// Either `node` or `makeDirectChild` should be provided.
// `makeDirectChild` will override `node` if both are provided.
const columnShape = {
  displayName: PropTypes.bool,
  hiddenMobile: PropTypes.bool,
  name: PropTypes.string.isRequired,
  node: PropTypes.elementType,
  props: PropTypes.shape(),
  makeDirectChild: PropTypes.func,
};

ClickableTable.propTypes = {
  caption: PropTypes.string.isRequired,
  captionSize: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.exact(columnShape)).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()),
  idField: PropTypes.string,
  nameField: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  rowRole: PropTypes.string,
  selectedState: PropTypes.shape(),
  tableClass: PropTypes.string,
};

ClickableTable.defaultProps = {
  captionSize: 4,
  data: null,
  idField: 'id',
  nameField: 'name',
  rowRole: 'row',
  selectedState: null,
  tableClass: '',
};

function Cell({ column, element }) {
  if (column.makeDirectChild) {
    return column.makeDirectChild(element);
  }
  let colProps = {};
  if (column.props) {
    colProps = Object.keys(column.props).reduce((acc, prop) => {
      if (prop !== 'node' && typeof column.props[prop] === 'function') {
        acc[prop] = column.props[prop](element);
      } else {
        acc[prop] = column.props[prop];
      }
      return acc;
    }, {});
  }
  return React.createElement(column.node, colProps);
}

Cell.propTypes = {
  column: PropTypes.shape(columnShape).isRequired,
  element: PropTypes.shape().isRequired,
};

export default ClickableTable;
