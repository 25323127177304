import React from 'react';
import PropTypes from 'prop-types';

export default function CardHead({
  setActive,
  title,
}) {
  return (
    <header aria-labelledby="modalTitle" className="modal-card-head">
      <p id="modalTitle" className="modal-card-title">{title}</p>
      <button
        type="button"
        aria-label="Close Modal"
        className="delete"
        onClick={() => setActive(false)}
      />
    </header>
  );
}

CardHead.propTypes = {
  setActive: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
