import React from 'react';

import { Figure, Title } from 'components/show';

import { appIconLarge } from 'utils';

function NoFaves() {
  return (
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="columns is-mobile">
              <div className="column is-left">
                <Title>Congratulations!</Title>
                <div className="content">
                  <p>
                    You&apos;re ready for refrigeration. It&apos;s time to
                    {' '}
                    <strong>keep your games fresh.</strong>
                    <br />
                    <br />
                    Click the
                    {' '}
                    <span className="icon has-text-primary" aria-label="Add Fave Button">
                      <i className="fas fa-heart" />
                    </span>
                    {' '}
                    to get started.
                  </p>
                </div>
              </div>
              <div className="column is-hidden-tablet is-one-third">
                <Figure size="128x128" src={appIconLarge.src} alt={appIconLarge.alt} />
              </div>
            </div>
          </div>
          <div className="column is-hidden-mobile">
            <Figure size="square" src={appIconLarge.src} alt={appIconLarge.alt} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default NoFaves;
