import React, {
  useEffect,
  useState,
} from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom';

import { useFavesContext } from 'context/faves';

import { Spinner } from 'components/show';
import { ReadFave } from 'components/show/fave';

import EditFaveModal from './EditFaveModal';

export default function ViewFave() {
  const [fave, setFave] = useState(null);
  const { favesState } = useFavesContext();
  const history = useHistory();
  const { index } = useParams();
  const [modalActive, setModalActive] = useState(false);

  useEffect(() => {
    if (favesState.faves[index]) {
      setFave(favesState.faves[index]);
    } else if (favesState.sessionChecked) {
      history.replace('/');
    }
  }, [favesState, index]);

  return (
    <>
      {fave
        ? (
          <>
            <ReadFave fave={fave} setModalActive={setModalActive} />
            <EditFaveModal
              fave={fave}
              index={Number(index)}
              modalActive={modalActive}
              setModalActive={setModalActive}
            />
          </>
        )
        : <Spinner />}
    </>
  );
}
