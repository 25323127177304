import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  Form,
  Notification,
  Title,
} from 'components/show';
import {
  Email,
  Password,
  SubmitButton,
  TextBox,
} from 'components/show/input';
import { register } from 'utils/api';
import { reduceErrors } from 'utils';

function RegistrationForm() {
  const history = useHistory();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const [response, responseJSON] = await register(name, email, password);
    if (response.ok) {
      history.push('/user/login');
      window.alert(`New account created for ${responseJSON.data.email}. Please log in.`);
    } else {
      window.alert(reduceErrors(responseJSON.errors));
    }
  };

  return (
    <section className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-half">
            <Title className="has-text-centered">Register for Refrigeration</Title>
            <Notification color="link" modifier="light">
              <Form handleSubmit={handleSubmit}>
                <TextBox
                  value={name}
                  setValue={setName}
                  name="name"
                  label="What should we call you?"
                  iconLeft="user"
                  placeholder="Enter your name"
                  required
                />
                <Email
                  value={email}
                  setValue={setEmail}
                  label="What&apos;s your email address?"
                  required
                />
                <Password
                  value={password}
                  setValue={setPassword}
                  label="Create a password."
                  placeholder="Create password"
                  required
                />
                <div className="field is-grouped">
                  <SubmitButton className="mr-2" disabled={!name || !email || !password} />
                  <div className="control">
                    <Link className="button is-primary is-outlined" to="/">Cancel</Link>
                  </div>
                </div>
              </Form>
              <p className="has-text-black">
                Already have an accout?
                <Link className="is-size-5 ml-2 mr-1 has-text-link" to="/user/login">Log In</Link>
                instead!
              </p>
            </Notification>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RegistrationForm;
