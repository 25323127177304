import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AuthDependentProvider } from 'context/auth';

import initialState from './initialState';
import reducer from './reducer';

const FaveWizardContext = React.createContext();

function FaveWizardProvider({ children, testInitialState }) {
  return (
    <AuthDependentProvider
      cache
      Context={FaveWizardContext}
      contextName="faveWizard"
      initialState={testInitialState === null ? initialState : testInitialState}
      reducer={reducer}
    >
      {children}
    </AuthDependentProvider>
  );
}
FaveWizardProvider.propTypes = {
  children: PropTypes.node,
  testInitialState: PropTypes.shape(),
};

FaveWizardProvider.defaultProps = {
  children: null,
  testInitialState: null,
};

/**
* Access like
* const { faveWizardState, faveWizardDispatch } = useFaveWizardContext();
*/
function useFaveWizardContext() {
  const context = useContext(FaveWizardContext);
  if (context === undefined) {
    throw new Error('useFaveWizardContext must be used within a FaveWizardProvider');
  }
  return context;
}

export { FaveWizardProvider, useFaveWizardContext };
