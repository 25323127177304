import React from 'react';
import PropTypes from 'prop-types';

export default function Burger({
  controls,
  label,
  active,
  setActive,
}) {
  return (
    <button
      type="button"
      aria-label={label}
      aria-expanded={active}
      aria-controls={controls}
      className={`button is-ghost navbar-burger burger ${active ? 'is-active' : ''}`}
      onClick={() => setActive(!active)}
    >
      <span aria-hidden="true" />
      <span aria-hidden="true" />
      <span aria-hidden="true" />
    </button>
  );
}

Burger.propTypes = {
  controls: PropTypes.string.isRequired,
  label: PropTypes.string,
  active: PropTypes.bool.isRequired,
  setActive: PropTypes.func.isRequired,
};

Burger.defaultProps = {
  label: 'expand menu',
};
