import { makeReducer } from 'utils';
import initialState from './initialState';

function favesReducer(currentState, action) {
  if (action.type === 'SET_FAVE') {
    const updatedFaves = currentState.faves;
    updatedFaves[action.index] = action.payload;
    return {
      ...currentState,
      faves: updatedFaves,
    };
  }
  return undefined;
}

const reducer = makeReducer(initialState, favesReducer);

export default reducer;
