import React from 'react';
import { Link } from 'react-router-dom';

import { Figure, Title } from 'components/show';

import { useAuthContext } from 'context/auth';

import { appIconLarge, twoByThree } from 'utils';

function HomePage() {
  const { authState } = useAuthContext();

  return (
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="columns is-mobile">
              <div className="column">
                <Title>Welcome to The Refrigerator</Title>
                <div className="content">
                  <p>
                    The Refrigerator is your video game repository.
                    Keep track of your favourite games,
                    and tell the world how much you love them.
                    <br />
                    <br />
                    So open the door.
                    Find an empty shelf.
                    <br />
                    <br />
                    <strong>Keep your games fresh.</strong>
                  </p>
                </div>
              </div>
              <div className="column is-hidden-tablet is-one-third">
                <Figure
                  alt={twoByThree.alt}
                  size={twoByThree.bulmaFigureSize}
                  src={twoByThree.src}
                />
              </div>
            </div>
            {authState.user
              ? (
                <div className="content">
                  <p>
                    Click the
                    {' '}
                    <span className="icon has-text-primary" aria-label="Add Fave Button">
                      <i className="fas fa-heart" />
                    </span>
                    {' '}
                    to get started.
                  </p>
                </div>
              )
              : (
                <div className="columns">
                  <div className="column">
                    <Link className="button is-medium is-fullwidth is-primary is-outlined" to="/user/login">
                      Log In
                    </Link>
                  </div>
                  <div className="column">
                    <Link className="button is-medium is-fullwidth is-primary" to="/user/register">
                      Register
                    </Link>
                  </div>
                </div>
              )}
            <div className="field is-grouped is-grouped-multiline">
              {/* <div className="control">
                <span className="tags has-addons">
                  <Link className="tag is-medium is-black is-light" to={{ pathname: 'https://gitlab.com/WillowPowell/refrigerator-is-running' }} target="_blank">Creator at willowpowell.com</Link>
                  <Link className="tag is-medium is-link" to={{ pathname: 'https://gitlab.com/WillowPowell/refrigerator-is-running' }} target="_blank">
                    <span className="icon">
                      <i className="fas fa-leaf" />
                    </span>
                  </Link>
                </span>
              </div> */}
              <div className="control">
                <span className="tags has-addons">
                  <Link className="tag is-medium is-black is-light" to={{ pathname: 'https://gitlab.com/WillowPowell/refrigerator-is-running' }} target="_blank">Code on GitLab</Link>
                  <Link className="tag is-medium is-success" to={{ pathname: 'https://gitlab.com/WillowPowell/refrigerator-is-running' }} target="_blank">
                    <span className="icon">
                      <i className="fab fa-gitlab" />
                    </span>
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div className="column is-hidden-mobile">
            <Figure size="square" src={appIconLarge.src} alt={appIconLarge.alt} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomePage;
