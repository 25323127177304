import { useReducer } from 'react';
import { useHistory } from 'react-router-dom';

/**
* It should be easy to add other handy react hook values!
* Just add them to the second argument of `action`.
*/
export default function useCustomReducer(reducer, initialState, init) {
  const [state, dispatch] = useReducer(reducer, initialState, init);
  const history = useHistory();

  function thunkDispatch(action) {
    if (typeof action === 'function') {
      action(thunkDispatch, {
        history,
      });
    } else {
      dispatch(action);
    }
  }
  return [state, thunkDispatch];
}
