const initialState = {
  userId: null,
  modalActive: false,
  fave: {
    game: null,
    topFave: false,
    phrase: '',
    hoursPlayed: 0,
  },
  lastSearch: {
    query: '',
    data: null,
  },
  step: 1,
  isLoading: false,
  complete: false,
};

export default initialState;
