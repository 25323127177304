import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import CardBody from './CardBody';
import CardFoot from './CardFoot';
import CardHead from './CardHead';

export default function Modal({
  children,
  active,
  isCard,
  name,
  setActive,
}) {
  useEffect(() => {
    function escapeHandler({ key }) {
      if (key === 'Escape' && active === true) {
        setActive(false);
      }
    }
    window.addEventListener('keydown', escapeHandler);
    return () => window.removeEventListener('keydown', escapeHandler);
  }, [active]);

  return (
    <div
      role="dialog"
      aria-modal="true"
      aria-label={name}
      aria-hidden={!active}
      className={`modal ${active ? 'is-active' : ''}`}
    >
      <div
        data-testid="modalBackground"
        role="none"
        className="modal-background"
        onClick={() => setActive(false)}
      />
      {isCard && <div className="modal-card">{children}</div>}
      {!isCard && children}
      {!isCard
        && (
          <button
            aria-label="Close Modal"
            className="modal-close is-large"
            onClick={() => setActive(false)}
            type="button"
          />
        )}
    </div>
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired,
  isCard: PropTypes.bool,
  name: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  isCard: false,
};

export { CardBody, CardFoot, CardHead };
