import React from 'react';
import PropTypes from 'prop-types';

export default function Control({
  iconLeft, iconRight, children,
}) {
  return (
    <div
      className={`control${
        iconLeft ? ' has-icons-left' : ''}${
        iconRight ? ' has-icons-right' : ''
      }`}
    >
      {children}
      {iconLeft && <span className="icon is-small is-left"><i className={`fas fa-${iconLeft}`} /></span>}
      {iconRight && <span className="icon is-small is-right"><i className={`fas fa-${iconRight}`} /></span>}
    </div>
  );
}

Control.propTypes = {
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Control.defaultProps = {
  iconLeft: null,
  iconRight: null,
};
