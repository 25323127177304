// TODO: Allow users to choose top faves.
import React, {
  useEffect,
} from 'react';

import { useAuthFetch } from 'context/auth';
import { useFavesContext } from 'context/faves';
import { useFaveWizardContext } from 'context/faveWizard';

import {
  ActionButton, Modal,
} from 'components/show';
import { CardHead } from 'components/show/Modal';

import { addFave, getFaves } from 'utils/api';
import { reduceErrors } from 'utils';

import ChooseGame from './ChooseGame';
import EnterMetadata from './EnterMetadata';

/*
* Multi-step wizard for creating a new Fave.
*/
function FaveWizard() {
  const { favesDispatch } = useFavesContext();
  const { faveWizardState, faveWizardDispatch } = useFaveWizardContext();
  const authFetch = useAuthFetch();

  const faveFormId = 'fave-form';

  useEffect(() => {
    if (
      faveWizardState.fave.game
      && faveWizardState.fave.phrase
      && faveWizardState.fave.phrase.trim()
      && faveWizardState.fave.hoursPlayed
    ) {
      faveWizardDispatch({
        type: 'SET',
        payload: { complete: true },
      });
    } else if (faveWizardState.complete === true) {
      faveWizardDispatch({
        type: 'SET',
        payload: { complete: false },
      });
    }
  }, [faveWizardState.fave, faveWizardState.complete]);

  function setModalActive(active) {
    faveWizardDispatch({
      type: 'SET',
      payload: {
        modalActive: active,
      },
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const [response, responseJSON] = await addFave(
      authFetch,
      faveWizardState.fave.game.id,
      faveWizardState.fave.topFave,
      faveWizardState.fave.phrase.trim(),
      faveWizardState.fave.hoursPlayed,
    );
    if (response.ok) {
      window.alert(`Successfully refrigerated ${faveWizardState.fave.game.name}`);
      faveWizardDispatch({ type: 'RESET_ALL' });
      getFaves(favesDispatch, authFetch);
    } else {
      window.alert(reduceErrors(responseJSON.errors));
    }
  }

  return (
    <>
      <ActionButton
        altText="Add Fave"
        buttonId="add-fave-action-button"
        icon="heart"
        onClick={() => setModalActive(true)}
      />
      <Modal
        active={faveWizardState.modalActive}
        isCard
        name="Add Fave Wizard"
        setActive={setModalActive}
      >
        <CardHead
          setActive={setModalActive}
          title={`Add A Fave: Step ${faveWizardState.step}`}
        />
        {faveWizardState.step === 1
            && <ChooseGame />}
        {faveWizardState.step === 2
          && <EnterMetadata formId={faveFormId} handleSubmit={handleSubmit} />}
      </Modal>
    </>
  );
}

export default FaveWizard;
