import { Figure, IconButton } from 'components/show';

import placeholder from 'utils/placeholder.png';

import DeleteFaveButton from '../DeleteFaveButton';

const columns = [
  {
    displayName: false,
    name: 'Cover',
    node: Figure,
    props: {
      alt: ({ fave }) => {
        if (fave.game.cover_url) {
          return `${fave.game.name} Cover Image`;
        }
        return 'Placeholder Image';
      },
      size: '64x64',
      src: ({ fave }) => fave.game.cover_url || placeholder,
    },
  },
  {
    displayName: false,
    name: 'Game',
    node: 'span',
    props: {
      children: ({ fave }) => fave.game.name,
      className: 'has-text-weight-medium',
    },
  },
  {
    hiddenMobile: true,
    name: 'Phrase',
    makeDirectChild: ({ fave }) => fave.phrase,
  },
  {
    hiddenMobile: true,
    name: 'Hours Played',
    makeDirectChild: ({ fave }) => fave.hours_played,
  },
  {
    displayName: false,
    name: 'Delete',
    node: DeleteFaveButton,
    props: {
      ariaLabel: 'Delete',
      className: 'button is-outlined is-primary',
      children: null,
      icon: 'fas fa-trash',
      iconSize: 'fa-lg',
      node: IconButton,
      faveId: ({ fave }) => fave.id,
    },
  },
  {
    displayName: false,
    name: 'Edit',
    node: IconButton,
    props: {
      ariaLabel: 'Edit',
      className: 'button is-primary',
      icon: 'fas fa-edit',
      iconSize: 'fa-lg',
      onClick: ({ edit }) => edit,
    },
  },
];

export default columns;
