// TODO: Page search results.
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/**
* This component has its own state.
* `query` is the *current state of the searchbox*.
*/
function SearchForm({
  defaultQuery, inputRef, handleSubmit, isLoading,
}) {
  const [query, setQuery] = useState(defaultQuery);

  return (
    <div className="container">
      <form onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(query);
      }}
      >
        <div className="field has-addons has-addons-fullwidth">
          <div className="control has-icons-right">
            <input
              id="search-input"
              aria-label="Search"
              type="search"
              name="search"
              required
              className="input is-fullwidth has-background-primary-light"
              placeholder="Search"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              ref={inputRef}
            />
            {query
              && (
              <span
                role="button"
                tabIndex="0"
                aria-label="Clear"
                className="icon is-right is-clickable"
                onClick={() => setQuery('')}
                onKeyDown={() => setQuery('')}
              >
                <i className="fas fa-times" />
              </span>
              )}
          </div>
          <div className="control is-fullwidth">
            <button
              id="submit-search"
              type="submit"
              disabled={!query}
              className={`button is-link ${isLoading ? 'is-loading' : ''}`}
            >
              Go
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

SearchForm.propTypes = {
  defaultQuery: PropTypes.string,
  inputRef: PropTypes.shape(),
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

SearchForm.defaultProps = {
  defaultQuery: '',
  inputRef: null,
  isLoading: false,
};

export default SearchForm;
