// TODO: handle paging search results.
// TODO: able to tab through the results.
import React, { useEffect, useRef } from 'react';

import { useFaveWizardContext } from 'context/faveWizard';
import { searchGames } from 'context/faveWizard/actions';
import {
  ClickableTable, SubTitle, SearchForm,
} from 'components/show';
import { CardBody, CardFoot } from 'components/show/Modal';

import gameTableColumns from './gameTableColumns';

function ChooseGame() {
  const { faveWizardState, faveWizardDispatch } = useFaveWizardContext();

  const focusRef = useRef(null);

  useEffect(() => {
    // This behaviour is tested in the FaveWizard integration test.
    if (focusRef.current && faveWizardState.modalActive) {
      focusRef.current.focus();
    }
  }, [faveWizardState.modalActive, focusRef]);

  return (
    <>
      <CardBody>
        <div className="container">
          <SubTitle>Search for your favourite game.</SubTitle>
          <SearchForm
            defaultQuery={faveWizardState.lastSearch.query}
            inputRef={focusRef}
            handleSubmit={(query) => faveWizardDispatch(searchGames(query))}
            isLoading={faveWizardState.isLoading}
          />
          {faveWizardState.lastSearch.data !== null && (
            <ClickableTable
              caption="Search Results"
              data={faveWizardState.lastSearch.data}
              onClick={(game) => {
                faveWizardDispatch({
                  type: 'SET_NESTED',
                  payload: {
                    fave: { game },
                  },
                });
              }}
              selectedState={faveWizardState.fave.game}
              columns={gameTableColumns}
              tableClass="mt-2 is-fullwidth"
            />
          )}
        </div>
      </CardBody>
      <CardFoot>
        <span className="mr-2">Select Game To</span>
        <button
          type="button"
          aria-label="Continue"
          onClick={() => faveWizardDispatch({
            type: 'SET',
            payload: {
              step: faveWizardState.step + 1,
            },
          })}
          disabled={!faveWizardState.fave.game}
          className="button is-success"
        >
          Continue
        </button>
      </CardFoot>
    </>
  );
}

export default ChooseGame;
