import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuthContext } from 'context/auth';
import { logout } from 'context/auth/actions';
import { useFavesContext } from 'context/faves';

import { A11yButton } from 'components/show';
import {
  Burger,
  Fave,
  MoreFavesDropdown,
  NavLink,
} from 'components/show/nav';

import logo from 'utils/Refrigerator-Horizontal-Logo.png';

function FridgeNav() {
  const { authState, authDispatch } = useAuthContext();
  const [burgerActive, setBurgerActive] = useState(false);
  const { favesState } = useFavesContext();
  const location = useLocation();
  const myFavesLink = (
    <NavLink
      activeClassName="is-active"
      className="navbar-item"
      to="/my-faves"
    >
      View All Faves
    </NavLink>
  );

  useEffect(() => {
    setBurgerActive(false);
  }, [location]);

  return (
    <section className="section">
      <div className="container">
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <NavLink className="navbar-item" to="/">
              <img src={logo} alt="Refrigerator logo." />
            </NavLink>
            {authState.user
              && (
                <Burger
                  controls="refrigerator-navbar"
                  active={burgerActive}
                  setActive={setBurgerActive}
                />
              )}
          </div>
          <div
            id="refrigerator-navbar"
            role="navigation"
            aria-label="menu"
            className={`navbar-menu ${burgerActive ? 'is-active' : ''}`}
          >
            <div className="navbar-start">
              {favesState.faves.slice(0, 3).map((fave, i) => (
                <Fave
                  data={fave}
                  key={fave.id}
                  route={`/fave/${i}`}
                />
              ))}
            </div>
            <div className="navbar-end">
              {authState.user && favesState.faves.length > 0 && (
                <>
                  {favesState.faves.length > 3
                    ? (
                      <MoreFavesDropdown data={favesState.faves.slice(3)}>
                        <>
                          <hr className="navbar-divider" />
                          {myFavesLink}
                        </>
                      </MoreFavesDropdown>
                    )
                    : myFavesLink}
                </>
              )}
              {authState.user
                && (
                  <div className="navbar-item has-dropdown is-hoverable">
                    <div role="button" aria-label="user-menu" className="navbar-link">
                      <span className="icon has-text-success">
                        <i className="fas fa-user" />
                      </span>
                    </div>
                    <div className="navbar-dropdown">
                      <A11yButton
                        elementType="div"
                        className="navbar-item is-clickable"
                        onClick={() => { authDispatch(logout); }}
                      >
                        Log Out
                      </A11yButton>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </nav>
      </div>
    </section>
  );
}

export default FridgeNav;
