import appIconLarge from './appIconLarge.png';

const icon = {
  alt: 'Refrigerator App Icon',
  src: appIconLarge,
  height: 1024,
  width: 1024,
};

export default icon;
