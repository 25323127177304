import React from 'react';

import { faveShape } from 'utils/propTypes';

export default function FaveDetailsList({ fave }) {
  return (
    <div className="content">
      <ul className="is-size-5">
        <li>{`Added as a fave on ${fave.created_at.split(' ', 4).join(' ')}.`}</li>
        <li>{`You've played for ${fave.hours_played} hours.`}</li>
      </ul>
    </div>
  );
}

FaveDetailsList.propTypes = {
  fave: faveShape.isRequired,
};
