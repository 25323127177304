import placeholder from './placeholder.png';

const placeholderImage = {
  alt: 'Placeholder Image',
  src: placeholder,
  height: 545,
  width: 545,
};

export default placeholderImage;
