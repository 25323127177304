import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ClickableTable } from 'components/show';

import { faveShape } from 'utils/propTypes';

import EditFaveModal from '../EditFaveModal';

import columns from './columns';

export default function ViewFavesList({ faves }) {
  const history = useHistory();
  const [faveIndex, setFaveIndex] = useState(0);
  const [modalActive, setModalActive] = useState(false);
  function mapData(fave, i) {
    return {
      fave,
      id: fave.id,
      edit: (e) => {
        e.stopPropagation();
        setFaveIndex(i);
        setModalActive(true);
      },
      name: fave.game.name,
    };
  }
  const [data, setData] = useState(faves.map(mapData));

  useEffect(() => {
    setData(faves.map(mapData));
  }, [faves]);

  return (
    <div className="container">
      {faves.length > 0 && (
      <>
        <ClickableTable
          caption="My Faves"
          columns={columns}
          data={data}
          onClick={(el, i) => { history.push(`/fave/${i}`); }}
          rowRole="link"
          selectedState={null}
          tableClass="is-fullwidth"
        />
        <EditFaveModal
          fave={faves[faveIndex]}
          index={faveIndex}
          modalActive={modalActive}
          setModalActive={setModalActive}
        />
      </>
      )}
    </div>
  );
}

ViewFavesList.propTypes = {
  faves: PropTypes.arrayOf(faveShape),
};

ViewFavesList.defaultProps = {
  faves: [],
};
